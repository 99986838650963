var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('Notificacion'),_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('v-img',{staticClass:"me-3 ",attrs:{"src":require('@/assets/images/logos/logoAkdemia_externo.png'),"max-height":"150","max-width":"150","alt":"logoAgendaMedica","contain":""}})],1)],1),_c('v-card-text',[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" Bienvenido a el Prototipo Akdemia! 👋🏻 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Haga que la administración de su aplicación sea fácil y divertida! ")])]),_c('v-card-text',[_c('validation-observer',{ref:"datos_basicos",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{ref:"form",attrs:{"id":"registro_temporal"},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',[_c('v-col',[_c('validation-provider',{attrs:{"name":"fields.tx_username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-text-field',{staticClass:"input-group--focused mb-4 text-lg",attrs:{"error-messages":errors,"label":"Correo Electrónico","required":""},model:{value:(_vm.fields.tx_username),callback:function ($$v) {_vm.$set(_vm.fields, "tx_username", $$v)},expression:"fields.tx_username"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fields.tx_apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-text-field',{staticClass:"input-group--focused mb-4 text-lg",attrs:{"error-messages":errors,"label":"Apellidos","required":""},model:{value:(_vm.fields.tx_apellidos),callback:function ($$v) {_vm.$set(_vm.fields, "tx_apellidos", $$v)},expression:"fields.tx_apellidos"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fields.tx_nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-text-field',{staticClass:"input-group--focused mb-4 text-lg",attrs:{"error-messages":errors,"label":"Nombres","required":""},model:{value:(_vm.fields.tx_nombres),callback:function ($$v) {_vm.$set(_vm.fields, "tx_nombres", $$v)},expression:"fields.tx_nombres"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fields.tx_direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-textarea',{staticClass:"input-group--focused mb-10 text-lg",attrs:{"error-messages":errors,"label":"Dirección","rows":"2","required":""},model:{value:(_vm.fields.tx_direccion),callback:function ($$v) {_vm.$set(_vm.fields, "tx_direccion", $$v)},expression:"fields.tx_direccion"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-text-field',{ref:"password",staticClass:"input-group--focused mb-4",attrs:{"append-icon":_vm.show1 ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff,"type":_vm.show1 ? 'text' : 'password',"error-messages":errors,"name":"input-10-1","label":"Clave","value":"","data-vv-name":"fields.tx_password"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.fields.tx_password),callback:function ($$v) {_vm.$set(_vm.fields, "tx_password", $$v)},expression:"fields.tx_password"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-text-field',{staticClass:"input-group--focused mb-6",attrs:{"append-icon":_vm.show2 ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff,"type":_vm.show2 ? 'text' : 'password',"error-messages":errors,"name":"input-10-2","label":"Confirmar clave","data-vv-name":"fields.tx_password_confirm","value":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.fields.tx_password_confirm),callback:function ($$v) {_vm.$set(_vm.fields, "tx_password_confirm", $$v)},expression:"fields.tx_password_confirm"}})],1)]}}],null,true)}),_c('v-row',{staticClass:"flex justify-between mt-5"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('v-btn',{attrs:{"depressed":""}},[_vm._v(" Cancelar ")])],1),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.loadingGuardar},on:{"click":function($event){return _vm.onSubmit()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v(" Registrar ")])],1)],1)],1)],1)]}}])})],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center flex-wrap mt-2"},[_c('span',{staticClass:"me-2"},[_vm._v(" ¿Ya tienes una cuenta? ")]),_c('router-link',{attrs:{"to":{ name:'pages-login' }}},[_vm._v(" Iniciar sesión ")])],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"173","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark':'light') + ".png"))}}),_c('v-img',{staticClass:"auth-tree",attrs:{"width":"247","height":"185","src":require("@/assets/images/misc/tree.png")}}),_c('v-img',{staticClass:"auth-tree-3",attrs:{"width":"377","height":"289","src":require("@/assets/images/misc/tree-3.png")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }