<template>
  <div class="auth-wrapper auth-v1">
    <Notificacion />

    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logoAkdemia_externo.png')"
              max-height="150"
              max-width="150"
              alt="logoAgendaMedica"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Bienvenido a el Prototipo Akdemia! 👋🏻
          </p>
          <p class="mb-2">
            Haga que la administración de su aplicación sea fácil y divertida!
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <validation-observer
            ref="datos_basicos"
            v-slot="{  }"
          >

        <v-form 
              id="registro_temporal"
              ref="form"
              @submit.prevent="" >
          <v-container>
          <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields.tx_username"
                    rules="required|email"
                  >
                    <v-row>
                          <v-text-field
                            v-model="fields.tx_username"
                            class="input-group--focused mb-4 text-lg"
                            :error-messages="errors"
                            label="Correo Electrónico"
                            required
                          ></v-text-field>
                    </v-row>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="fields.tx_apellidos"
                    rules="required"
                  >
                    <v-row>
                          <v-text-field
                            v-model="fields.tx_apellidos"
                            class="input-group--focused mb-4 text-lg"
                            :error-messages="errors"
                            label="Apellidos"
                            required
                          ></v-text-field>
                    </v-row>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields.tx_nombres"
                    rules="required"
                  >
                    <v-row>
                          <v-text-field
                            v-model="fields.tx_nombres"
                            class="input-group--focused mb-4 text-lg"
                            :error-messages="errors"
                            label="Nombres"
                            required
                          ></v-text-field>
                    </v-row>
                  </validation-provider>

                  <!--<validation-provider
                    v-slot="{ errors }"
                    name="fields.tx_cod_postal"
                    rules="required"
                  >
                    <v-row>
                      <v-autocomplete
                        v-model="selected_cod_postal"
                        class="mb-4"
                        :items="items"
                        :clearable=true
                        :loading="isLoading"
                        :error-messages="errors"
                        :search-input.sync="search"
                        hide-no-data
                        hide-selected
                        item-text="tx_desc_postal"
                        item-value="tx_cod_postal"
                        label="Código postal"
                        placeholder="Introduzca datos que desea buscar"
                        return-object
                      ></v-autocomplete>
                    </v-row>
                  </validation-provider>
-->
                  <validation-provider
                    v-slot="{ errors }"
                    name="fields.tx_direccion"
                    rules="required"
                  >
                    <v-row>
                      <v-textarea
                          v-model="fields.tx_direccion"
                          class="input-group--focused mb-10 text-lg"
                          :error-messages="errors"
                          label="Dirección"
                          rows="2"
                          required

                      ></v-textarea>                      
                    </v-row>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    vid="password"
                    rules="required|min:8"
                  >
                  <v-row>
                      <v-text-field
                        ref="password"
                        v-model="fields.tx_password"
                        :append-icon="show1 ? icons.mdiEye : icons.mdiEyeOff"
                        :type="show1 ? 'text' : 'password'"
                        :error-messages="errors"
                        name="input-10-1"
                        label="Clave"
                        value=""
                        class="input-group--focused mb-4"
                        data-vv-name="fields.tx_password"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                  </v-row>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:8|confirmed:password"
                  >
                  <v-row>
                      <v-text-field
                        v-model="fields.tx_password_confirm"
                        :append-icon="show2 ? icons.mdiEye : icons.mdiEyeOff"
                        :type="show2 ? 'text' : 'password'"
                        :error-messages="errors"
                        name="input-10-2"
                        label="Confirmar clave"
                        data-vv-name="fields.tx_password_confirm"
                        value=""
                        class="input-group--focused mb-6"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                  </v-row>

                  </validation-provider>
                  <v-row class="flex justify-between mt-5">

                    <router-link
                      to="/dashboard"
                    >
                    <v-btn  depressed>
                      Cancelar
                    </v-btn> 
                    </router-link>    
                    <v-btn
                      depressed
                      color="primary"
                      :loading="loadingGuardar"
                          @click="onSubmit()"
                    >
                          Registrar
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>                                               

                  </v-row>
            </v-col>
            </v-container>              
        </v-form>
        </validation-observer>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            ¿Ya tienes una cuenta?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Iniciar sesión
          </router-link>
        </v-card-text>

        <!-- divider
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>
 -->
        <!-- social link
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
 -->        
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >


    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEye, mdiEyeOff, mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { SET_ERROR, SET_MODAL_USUARIO } from '@/store/mutations.type'
import {  CREATE_USUARIO_TEMP } from '@/store/actions.type'
import { extend } from 'vee-validate'
import Notificacion from "@/components/Utilitarios/Notificacion";


import { required, email, min, max, confirmed } from 'vee-validate/dist/rules'

extend('confirmed', {
  ...confirmed,
  message: 'Claves distintas'
})
extend('required', {
  ...required,
  message: 'Campo requerido'
})

extend('min', {
  ...min,
  message: 'Valor minimo 8'
})

extend('max', {
  ...max,
  message: 'Valor máximo 10'
})

extend('email', {
  ...email,
  message: 'Debe introducir un correo valido'
})
export default {
  name: 'RegistroUsuarioTempView',
  data () {
  return {
      descriptionLimit: 60,
      entries: [],
      loadingGuardar: false,
      isLoading: false,
      model: null,
      search: null,

      show1: false,
      show2: false,
      show3: false,
      show4: false,
      offset: 0,
      limit: 10,            
      robot: false,
      showModalUsuario: false,
      sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      noti: {
          group: "top",
          title: "Succes",
          text: "Operación culmino satisfactoriamente!!!"        
      },
      loginForm: {
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: ''
      },   
      codPostal: [],
      fullPage: true,      
      selected_cod_postal:'',
      fields: {
        tx_username: null,
        tx_password: null,
        tx_password_confirm: null,
        tx_nombres: '',
        tx_apellidos: '',
        tx_direccion: '',
        tx_cod_postal_json: '',
        usuario_perfil: '5',
        co_status: 'REG'
      },
    }
  },  
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiEye, 
        mdiEyeOff,        
      },
    }
  },
  watch: {

  },  
  computed: {

  },
  components: {
    //MaskedInput,
    Notificacion
  },  
  methods: {
    onKeydown (event) {
        const char = String.fromCharCode(event.keyCode)
        if (!/[0-9]/.test(char)) {
            event.preventDefault()
            event.target.nextElementSibling.focus()
        }
    },    
    onCancel() {
      console.log('User cancelled the loader.')
    },    
    async onSubmit () {        
    const isValid = await this.$refs.datos_basicos.validate()
      // simulate AJAX
      if (isValid) {
          this.loadingGuardar = true

          const formData = new FormData()
          for (const property in this.fields) {
              formData.append(`${property}`, `${this.fields[property]}`)
          }
          this.$store.dispatch(CREATE_USUARIO_TEMP, formData).then(response => {          
              //window.location = window.location.origin + window.location.pathname
              this.$notify(
                {
                  group: "generic",
                  title: "Success",
                  text: "Operación realizada satisfactoriamente" + response,
                },
                4000
              );      

          }, error => {
              console.error("Hay un error", error)
              this.$notify(
                {
                  group: "error",
                  title: "Error",
                  text: "No se pudo realizar la operación",
                },
                4000
              );      

              this.$store.commit(SET_ERROR, 'Error al Ingresar, validar su usuario o clave')
          })      



        setTimeout(() => {
              //this.$refs.notifica.onClickTop() 
              this.$store.commit(SET_MODAL_USUARIO, false)
              this.loadingGuardar = false
          },3000)         
        //this.$refs.notifica.onClickTop()              
    
      }
    },    
    cancelar() {
        this.$store.commit(SET_MODAL_USUARIO, false)
    }

  },   
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
